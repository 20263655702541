(function ($) {

    $.fn.elasticMenu = function (options) {
        var self = $(this);
        var logo = $("#header-logo");
        self.options = $.extend({
            max_height: 140,
            min_height: 100,
            max_opacity: 1,
            min_opacity:0,
            wrap: false,
            bg: false
        }, options);

        self.init = function () {
            $(window).on("scroll load resize", self.scroll);


        };

        self.scroll = function (evt) {

            var s = $(window).scrollTop();

            if($(window).outerWidth() < 992){
                self.options.max_height = 80;
                self.options.min_height = 70;
            }else{
                self.options.max_height = 130;
                self.options.min_height = 74;
                self.options.line_height = 100;
            }


            if ((s >= -self.options.min_height) && (s <= (self.options.max_height - self.options.min_height))) {

                var h = self.options.max_height;
                self.removeClass('shrink');
                logo.removeClass('shrink-logo');

                self.stop().animate({
                    //height: h,
                    overflow: "visible",
                }, 10);

                self.find("ul.nav > li > a, a.navbar-brand,.cartholder").stop().animate({
                    //height: h,
                    //lineHeight: h,
                    overflow: "visible",
                }, 10);

                if (self.options.wrap !== false) {
                    self.options.wrap.stop().animate({
                        paddingTop: h
                    }, 10);
                }

                if (self.options.bg !== false) {


                    var opacity = (((h - self.options.max_height) * 100) / ((self.options.max_height - self.options.min_height) / 2) / 2) / 100;



                    if(opacity > self.options.max_opacity){
                        opacity = self.options.max_opacity;
                    }

                    if(opacity < self.options.min_opacity){
                        opacity = self.options.min_opacity;
                    }

                    self.find(".bg").stop().animate({
                        opacity: Math.abs(opacity)
                    }, 10);

                }



            } else {
                self.addClass('shrink');
                logo.addClass('shrink-logo');
                self.stop().animate({
                    //height: self.options.min_height,
                    //lineHeight: self.options.line_height,
                    overflow: "visible",
                }, 15);
                self.find("ul.nav > li > a, a.navbar-brand").stop().animate({
                    //height: self.options.min_height,
                    //lineHeight: self.options.line_height,
                    overflow: "visible",
                }, 15);

                if (self.options.wrap !== false) {
                    self.options.wrap.stop().animate({
                        paddingTop: self.options.min_height - s
                    }, 10);
                }

                if (self.options.bg !== false) {
                    self.find(".bg").stop().animate({
                        opacity: self.options.max_opacity
                    }, 10);
                    self.addClass("filed");
                }


            }

        };


        self.init();
        return self;
    };



})(jQuery);  
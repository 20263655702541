(function ($) {

    var current_section = null;

    $.fn.arrow_down = function () {

        var self = $(this);

        self.click = function (evt) {

            var scrolltoelement = self.closest("section").eq(0).next();

            if (scrolltoelement.length > 0) {
                var minus = 100;
                if ($(window).outerWidth() < 992) {
                    minus = 60;
                }

                $("html,body").stop().animate({
                    scrollTop: (scrolltoelement.position().top - minus) + 2
                }, 800);

                current_section = scrolltoelement;
 
            }

        };

        self.on("click", self.click);

        return self;
    };

    // current_section = $("body").find("section").first();

    // $(".arrow_down").each(function () {
    //     $(this).arrow_down();
    // });



    // var scroll = function (elm) {


    //     if (elm.length > 0) {
    //         var minus = 100;
    //         if ($(window).outerWidth() < 992) {
    //             minus = 60;
    //         }

    //         $("html,body").stop().animate({
    //             scrollTop: (elm.position().top - minus) + 2
    //         }, 800);

    //         current_section = elm;

    //     }

    // };

    // $(document).keydown(function (event) {

    //     if (event.keyCode == 38) {
    //         event.preventDefault();
    //         scroll(current_section.prev("section"));
    //     } else if (event.keyCode == 40) {
    //         event.preventDefault();
    //         scroll(current_section.next("section"));  
    //     }
    // });


})(jQuery, window, document);



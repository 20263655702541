(function ($) {

	var GF = function() {

		var s = this;

		s.init = function() {
			s.navbar();

			$(window).on('preloader_removed', function() {
			});
			
			$(window).on('load', function() {
				$(window).trigger('resize');
			});

		};	



		s.navbar = function() {
			if(($('body').hasClass('home') && !$('body').hasClass('landing-page')) || $('body').hasClass('hem')) {
				$('#main-nav').addClass('transparent');
				$(window).on('load scroll resize', function() {
					if( $(window).width() >= 768) {
						if($(this).scrollTop() > 760) {
							$('#main-nav').removeClass('bye');
							$('#main-nav').removeClass('transparent');
						} else {
							if(!$('#main-nav').hasClass('transparent')) {
								$('#main-nav').addClass('transparent');
								$('#main-nav').addClass('bye');
								setTimeout(function() {
									$('#main-nav').removeClass('bye');
								}, 500);
							}
						}
					} else {
						$('#main-nav').addClass('transparent');
					}
				})
				
			}
		};


		s.findHighest = function(grid) {
			var highest = 0;
			var el = grid.find('.col');
			$.each(el, function() {
				highest = $(this).height() > highest ? $(this).height() : highest;
			});
			return highest;
		}

		s.init();

	};

	$(document).ready(function() {
		new GF();
	});
    


})(jQuery);

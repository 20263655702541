(function($){


	var TermSort = function() {
		var s = this;


		$(".sortable-term").on('click', function(){

            // let choices = [];
            // let els = document.getElementsByName('terms-category');
            // for (let i=0;i<els.length;i++){
             //    if ( els[i].checked ) {
             //        choices.push(els[i].value);
             //    }
            // }
			// console.log(choices);

            //var categories = $("input[name='terms-category[]']");
            //var categories = $(".terms-category input:checked");
            //var grades = $("input[name='terms-grade[]']");

            //
            // $.each(categories, function (key, val) {
            //     console.log(val);
            // });

            // var values = $('.terms-category input:checked').map(function() {
            //     return this;
            //  }).get().join(',');
			// console.log(values);
            //console.log(values);
            //console.log(grades);
		});

	};

	$(document).ready(function() {
		new TermSort();
	})

})(jQuery);
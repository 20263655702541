(function($) {

    var sameheight = function() {
        var self = this;
        self.window = $(window);

        self.init = function() {
                self.window.on("load", function() {
                    self.window.on("resize preloader_removed", self.make_same_height).trigger("resize");
                });

        };

        self.make_same_height = function(event) {
            
            if (!self.do_scroll(self.window) && event.type != "preloader_removed")
                return false;
            
            $("body").find("[gf-same-height]").each(function() {
                var element = $(this);
                if (element.next("div").length > 0) {
                    element.css({
                        "height": element.next("div").outerHeight()
                    });
                    element.find("div").first().css({
                        "height": element.next("div").outerHeight()
                    });
                }else if(element.prev("div").length > 0){
                    element.css({
                        "height": element.prev("div").outerHeight()
                    });
                }

            });

        };

        self.width = 0;
        self.height = 0;
        self.do_scroll = function(window) {

            if (window.outerWidth() !== self.width && window.outerWidth() >= 768) {
                self.width = window.outerWidth();
                return true;
            }

            return false;
        };

        self.init();
        return self;
    };

    $(document).ready(function() {
        var sh = new sameheight();
    });


})(jQuery);